.my-listings {
  padding: 0.5rem 2rem 2rem 2rem;
}

.create-property-package {
  text-align: left;
  margin-bottom: 1rem;
}

.create-package-button {
  padding: 0.6rem 1.5rem;
  background-color: rgb(35, 167, 255); /* Match your site's color scheme */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.create-package-button:hover {
  background-color: #0056b3;
}

.listings-container {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.mylistings-error {
  padding-left: 0.5rem;
  padding-top: 1rem;
  color: rgb(0, 0, 0);
}
