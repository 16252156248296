.share-url-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .share-url-content {
    background: white;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;
    padding: 2rem;
    position: relative;
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .share-url-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .share-url-header h2 {
    margin: 0;
    font-size: 1.25rem;
    padding-bottom: 2rem; /* Optional to match alignment with EditEmailModal */
  }
  
  .share-url-close-button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: #ffffff;
    border: 2px solid #d5d5d5;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    color: #000000;
    transition: all 0.3s ease;
  }
  
  .share-url-close-button:hover {
    color: #ffffff;
    background-color: #b12525;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .share-url-close-button:before {
    content: '×';
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    margin-bottom: 2px;
  }
  
  .share-url-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .share-url-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #868686;
    border-radius: 4px;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
  }
  
  .share-url-copy-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .share-url-copy-button:hover {
    background-color: #0056b3;
  }
  