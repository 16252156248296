/* Login.css */
.login-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use vh to ensure full height */
  background-color: #f9f9f9; /* A light background color */
}

.login-content-wrapper {
  flex-grow: 1; /* Ensure it takes up the available space */
  display: flex;
  justify-content: center; /* Center LoginForm horizontally */
  align-items: center; /* Center LoginForm vertically */
  padding: 2rem; /* Add some padding around the content */
}

/* Footer should already be styled from Footer.css, just make sure it's positioned correctly */
/* Add any additional global styles that you want to apply to the login page here */

/* Responsiveness */
@media (max-width: 768px) {
  .login-content-wrapper {
    padding: 1rem; /* Reduce padding on smaller screens */
  }
}
