/* Terms.css */

.terms-section {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  padding-top: 0.5rem;
}

.term {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}

.term.centered {
  justify-content: center;
}

.term:last-child {
  border-bottom: none;
}

.purchase-price-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333333;
  text-align: center;
  padding-bottom: 0.5rem;
}

.label {
  font-weight: bold;
  color: #2c3e50;
  flex: 1.5;
}

.value {
  font-size: 1rem;
  color: #2c3e50;
  flex: 1;
  text-align: right;
}

.special-terms {
  display: block;
  text-align: left;
  padding-bottom: 1rem;
}

.special-terms-value {
  margin-top: 0.5rem;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
