.pdf-viewer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.95);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
}

.pdf-viewer-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: rgba(26, 26, 26, 0.9);
  color: white;
  z-index: 1002;
}

.pdf-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-title {
  margin: 0;
  font-size: 1.3rem;
}

.pdf-type {
  margin: 0;
  font-size: 1rem;
  color: #bbb;
}

.pdfviewer-close-button {
  position: absolute;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.pdfviewer-close-button:hover {
  color: #ff4444;
}

.pdf-viewer-container {
  flex: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5rem 1rem 1rem;
  position: relative;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  position: relative;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
}

.react-pdf__Page__canvas {
  display: block;
}

.react-pdf__Page__textContent {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
  user-select: text;
  cursor: text;
}

.react-pdf__Page__textContent span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.react-pdf__Page__annotations {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

::selection {
  background: rgba(0, 123, 255, 0.3);
}

::-moz-selection {
  background: rgba(0, 123, 255, 0.3);
}

.pdf-toolbar-container {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.pdf-toolbar-container.visible {
  opacity: 1;
}

.pdf-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.85rem;
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: 35px;
}

.pdf-toolbar button,
.pdf-toolbar span {
  margin: 0 0.7rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.pdf-toolbar button:hover {
  color: #4CAF50;
}

.pdf-toolbar button:disabled {
  color: #666;
  cursor: not-allowed;
}

.page-info {
  font-size: 1.3rem;
  color: #bbb;
  margin: 0 0.85rem;
}

.pdf-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1003;
}

.pdf-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pdf-page-container {
  position: relative;
}