/* FeaturesPage.css */

.features-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.features-page-content {
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
}

.features-page-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.features-page-menu {
  display: flex;
  justify-content: center;
  background: #f9f9f9;
  padding: 0.8rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.features-page-menu-item {
  padding: 0.5rem 0.9rem;
  margin: 0 0.5rem;
  background: #e7e7e7; /* Subtle background color */
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border-radius: 10px; /* Rounded corners */
  color: #333; /* Text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.features-page-menu-item:hover,
.features-page-menu-item.active {
  background-color: rgb(27, 92, 243); /* Brand color for hover and active state */
  color: rgb(255, 255, 255); /* White text for contrast */
}

.features-page-menu-item:not(.active):hover {
  background-color: rgb(0, 83, 172); /* Lighter shade for hover on non-active buttons */
}
