/* DocumentsListSelection.css */

.dls-documents-list {
  width: 100%;
  overflow-y: auto;
}

.dls-documents-list h3 {
  margin: 0;
  padding-top: 2rem;
  padding-left: 3rem; /* Ensure padding aligns with list */
  padding-bottom: 1rem;
  font-size: 1.3rem;
}

.dls-documents-list p {
  margin: 0;
  padding-left: 3rem; /* Align with h3 */
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  color: #555;
  line-height: 1.5; /* Improve readability */
}

.dls-document-item {
  padding: 1rem;
  padding-left: 1.5rem; /* Align padding with header */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start */
  border-bottom: 1px solid #ddd;
  color: black;
  font-weight: 500;
  transition: background-color 0.3s;
}

.dls-document-item:hover {
  background-color: #f1f1f1;
}

.dls-document-item.selected {
  background-color: #007bff; /* Highlight color */
  color: white; /* Text color for highlighted item */
}

.dls-document-item.selected .dls-document-type {
  color: white; /* Text color for highlighted document type and pages */
}

.dls-document-info {
  display: flex;
  flex-direction: column;
}

.dls-document-title {
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
}

.dls-document-type {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 0.9rem;
}

.dls-document-pages {
  font-size: 0.9rem;
  color: #888;
  width: 125px;
  text-align: center;
  background-color: #f1f1f1; /* Default background color */
  padding: 0.25rem 0.8rem; /* Add some padding */
  border-radius: 1rem; /* Make it rounded */
  display: inline-block; /* Ensure it's inline-block */
  transition: background-color 0.3s;
}

.dls-document-pages.morethanzero {
  background-color: #32b643;
  color: white; /* Text color when pages are selected */
}