/* Pagination.css */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .page-item {
    padding: 0.75rem 1rem;
    margin: 0 0.5rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  
  .page-item:hover {
    background-color: #f2f2f2;
  }
  
  .page-item.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  