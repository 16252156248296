/* FeatureContent.css */

.features-page-feature-content {
  display: flex;
  padding: 2rem;
}

.features-page-feature-image {
  flex: 1;
}

.features-page-feature-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.features-page-feature-text {
  flex: 1;
  padding-left: 3rem;
}

.features-page-feature-text h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

.features-page-feature-text p {
  margin-bottom: 1rem;
}
