.upgrade-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.upgrade-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  flex: 1;
}

.upgrade-title {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 0.8rem;
}

.upgrade-description {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #555;
}

.upgrade-content {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.benefits-section {
  width: 45%;
}

.benefits-section h2 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.benefits-section h3 {
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.benefits-section ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.2rem;
}

.benefits-section li {
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #333;
}

.subscription-section {
  width: 48%;
}

.plan-section,
.payment-section,
.promotion-section,
.terms-section,
.enroll-section {
  margin-bottom: 2rem;
}

.plan-section h2,
.payment-section h2,
.promotion-section h2 {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.plan-section label {
  display: block;
  margin-bottom: 0.4rem;
  font-size: 0.95rem;
  cursor: pointer;
  color: #333;
}

.discount-tag {
  background-color: #28a745;
  color: white;
  padding: 0.25rem 0.4rem;
  border-radius: 5px;
  margin-left: 0.4rem;
  font-size: 0.8rem;
}

.payment-section label {
  display: block;
  margin-bottom: 0.4rem;
  font-size: 0.95rem;
  color: #555;
}

.payment-section input {
  width: 100%;
  padding: 0.6rem;
  margin-bottom: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.95rem;
}

.expiration-cvc {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}

.billing-address h3 {
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  color: #333;
}

.city-state-zip {
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
}

.city-state-zip div {
  flex: 1;
}

.promotion-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.promotion-section input {
  flex: 1;
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.95rem;
}

.redeem-button {
  padding: 0.6rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95rem;
}

.redeem-button:hover {
  background-color: #0056b3;
}

.terms-section label {
  font-size: 0.875rem;
  color: #555;
}

.terms-section a {
  color: #007bff;
  text-decoration: none;
}

.terms-section a:hover {
  text-decoration: underline;
}

.enroll-section {
  text-align: center;
}

.enroll-section p {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: #555;
}

.enroll-button {
  width: 100%;
  padding: 0.8rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.enroll-button:hover {
  background-color: #218838;
}