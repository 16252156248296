.agent-info-toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
  
  .agent-info-radio {
    flex: 1;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  
  .agent-info-radio input {
    margin-right: 0.5rem;
  }
  
  .agent-info-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .agent-info-label {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .agent-info-input {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .agent-info-button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .agent-info-step-back-button,
  .agent-info-next-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .agent-info-step-back-button {
    background-color: #ccc;
    color: #fff;
  }
  
  .agent-info-next-button {
    background-color: #007bff;
    color: #fff;
  }
  