.offers-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.offers-page-item {
  padding: 0.75rem 1rem;
  margin: 0 0.5rem;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.5s, box-shadow 0.5s ease;
  font-weight: 600;
}

.offers-page-item:hover {
  background-color: #f2f2f2;
}

.offers-page-item.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.offers-page-item.active:hover {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}

.offers-page-item:disabled {
  background-color: #b0c4de;
  cursor: not-allowed;
}
