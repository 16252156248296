/* LoginForm.css */

.log-form {
  width: 90%;
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', sans-serif;
}

.log-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #333333;
  text-align: center;
}

.log-form-inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.log-form-group {
  display: flex;
  flex-direction: column;
}

.log-label {
  display: block;
  font-size: 1rem;
  color: #555555;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.log-input {
  width: 100%;
  height: 36px;
  padding: 0.5rem 1rem;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.2s, box-shadow 0.2s;
  box-sizing: border-box;
}

.log-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.log-input-invalid {
  border-color: #dc3545;
}

.log-error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  font-weight: 500;
}

.log-button {
  width: 100%;
  height: 42px;
  padding: 0 0.5rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.log-button:hover {
  background-color: #0056b3;
}

.log-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.log-password-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.log-password-field .log-input {
  flex: 1;
}

.log-password-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: #6c757d;
  font-size: 1.25rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.log-alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.log-alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.log-alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.log-footer {
  margin-top: 2rem;
  text-align: center;
}

.log-footer p {
  padding-top: 0.5rem;
  padding-bottom: 0rem;
}

.log-footer a {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
}

.log-footer a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .log-form {
    width: 100%;
    max-width: none;
    margin: 1rem auto;
    padding: 1.5rem;
  }
}