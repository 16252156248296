  .summary-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .summary-item {
    border: 1px solid #ddd;
    padding: 1.5rem;
    padding-top: 0rem;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .summary-item h3 {
    margin-bottom: 1.5rem;
  }
  
  .summary-item p {
    margin: 1rem 0;
  }
  
  .description-label {
    font-weight: 500;
  }
  