.messages-section {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  padding-top: 1rem;
}

.messages-section-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #333333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.message-bubble {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem; /* Increased space between messages */
  position: relative;
}

.buyer-message .message-content {
  background-color: #f0f0f0;
  color: #333;
  border-radius: 15px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  margin-left: 10px;
  max-width: 70%;
  font-size: 1rem;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.response-message {
  display: flex;
  justify-content: flex-end;
}

.response-message .message-content {

  background-color: #e7f3ff;
  color: #333;
  border-radius: 15px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  margin-left: 10px;
  max-width: 70%;
  font-size: 1rem;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-timestamp {
  font-size: 0.75rem;
  color: #888;
  margin-top: 0.5rem;
}

.agent-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 0rem;
}

.response-message .agent-avatar-container {
  margin-left: 1.5rem;
}

.right-avatar {
  margin-left: 3rem;
  margin-right: 0;
}

.agent-avatar {
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.agent-avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
