.activity-tab {
  padding: 1rem;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.activity-stats {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.activity-stat {
  text-align: center;
}

.stat-number {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.stat-label {
  font-size: 1rem;
  color: #666;
}

.activity-list {
  margin-top: 0.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.activity-item:last-child {
  border-bottom: none;
}

.activity-info {
  display: flex;
  flex-direction: column;
}

.activity-date {
  font-size: 0.9rem;
  color: #888;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.activity-user {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.activity-document {
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}

.activity-document:hover {
  text-decoration: none;
}

.no-activities-message {
  margin-top: 2rem;
  margin-left: 1rem;
  color: #666;
  font-size: 16px;
}
