.prompt-csp-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: 'Inter', sans-serif;
  }
  
  .prompt-csp-modal .modal-content {
    background: #ffffff;
    padding: 2rem;
    border-radius: 20px;
    width: 700px;
    max-width: 90%;
    max-height: 90vh;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  .prompt-csp-modal .offer-modal-header {
    margin-bottom: 2.5rem;
  }
  
  .prompt-csp-modal .offer-modal-header h2 {
    font-size: 1.5em;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .prompt-csp-modal .offer-modal-header p {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 2rem;
  }
  
  .prompt-csp-modal .offer-modal-header ul {
    color: #444;
    padding-left: 2.5rem;
  }
  
  .prompt-csp-modal .offer-modal-header li {
    margin-bottom: 1.5rem;
  }

  
  .prompt-csp-modal .offer-modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
  
  .prompt-csp-modal .offer-button {
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .prompt-csp-modal .offer-button.secondary {
    background-color: #f8f9fa;
    color: #333;
    border: 1px solid #ced4da;
  }
  
  .prompt-csp-modal .offer-button.secondary:hover {
    background-color: #e9ecef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .prompt-csp-modal .offer-button.primary {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .prompt-csp-modal .offer-button.primary:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }