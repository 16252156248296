/* AutoFillForms.css */

.auto-fill-forms {
    width: 100%;
  }
  
  .modal-step {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .modal-step h2 {
    font-size: 1.5em;
    margin-bottom: 1rem;
    color: #555555;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }

  .include-button {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #898888;
    border-radius: 10px;
    padding: 10px 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    margin-right: 1rem;
  }
  
  .include-button:hover {
    transform: scale(1.05);
    background-color: #0056b3;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
  }
  
  .step-back-button,
  .next-button {
    color: #ffffff;
    padding: 0.8rem 1.2rem;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: background-color 0.5s ease;
  }
  
  .step-back-button {
    background-color: #a09f9f;
    width: 46%;
    margin-left: 0.55rem;
  }
  
  .next-button {
    background-color: #007bff;
    width: 46%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.55rem;
  }
  
  .step-back-button:hover {
    background-color: #7c7b7b;
    cursor: pointer;
  }
  
  .next-button:hover {
    background-color: #0056b3;
    cursor: pointer;
  }
  