.tab-section {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px solid #dfdfdf;
}

.tab-navigation {
  display: flex;
  border-bottom: 2px solid #e7e7e7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.tab-button {
  flex: 1;
  background-color: #ffffff;
  color: #333;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  font-weight: 700;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-button:first-child {
  border-left: none;
}

.tab-button:last-child {
  border-right: none;
}

.tab-button.active {
  background-color: #007bff;
  color: #fff;
  box-shadow: inset 0 -2px 0 #0056b3;
}

.tab-button:hover {
  background-color: #e7e7e7;
}

.tab-button.active:hover {
  background-color: #0056b3;
  color: #fff; /* Ensures the text color stays white */
}

.tab-content {
  padding: 20px;
}
