.my-listing-dashboard-content {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.2rem;
  flex: 1;
  background-color: #f4f4f4;
}

.my-listing-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}