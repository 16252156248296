/* /Tabs/Settings/Settings.css */

.settings-container {
  padding: 2rem;
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative; /* Ensure that the overlay covers the entire container */
}

.settings-section {
  margin: 0 40px 20px 40px; /* Add horizontal margin */
}

.settings-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.settings-description {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
}

.archive-button,
.settings-delete-button {
  border-radius: 10px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.archive-button {
  background-color: #dfdfdf; /* Neutral grey */
  color: #000;
  border: none;
}

.archive-button:hover {
  background-color: #868686; /* Neutral grey */
  color: #fff;
}

.danger-zone .settings-delete-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
}

.danger-zone .settings-delete-button:hover {
  background-color: #c0392b;
}

/* Spinner overlay styles */
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Shaded background similar to Documents.css */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it covers everything else */
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
