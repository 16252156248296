/* PFLoginForm.css */

.pf-login-form-container {
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.pf-login-form-container h2 {
  margin-bottom: 1rem;
}

.pf-login-error {
  color: red;
  margin-bottom: 0.5rem;
}

.pf-login-form-group {
  margin-bottom: 1rem;
}

.pf-login-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.pf-login-form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #2563eb;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
}

button:disabled {
  background-color: #6b7280;
}
