/* Signup.css */

.signup-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.signup-content-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

@media (max-width: 768px) {
  .signup-content-wrapper {
    padding: 1rem;
  }
}