.activity-sort-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 0rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
  }
  
  .filter-sort-section {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  
  .activity-filter-section,
  .activity-sort-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  
  .activity-filter-section label,
  .activity-sort-section label {
    font-weight: 600;
    color: #333;
    margin-right: 0.5rem;
    margin-bottom: 0.8rem;
  }
  
  .activity-filter-section select,
  .activity-sort-section select {
    padding: 0.5rem 1rem;
    width: 150px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    font-size: 1rem;
    color: #333;
    transition: border-color 0.3s;
    appearance: none;
    outline: none;
  }
  
  .activity-filter-section select:hover,
  .activity-sort-section select:hover,
  .search-input input:hover {
    border-color: #007bff;
  }
  
  .activity-search-section {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
  }
  
  .search-form {
    display: flex;
    align-items: center;
  }
  
  .search-input {
    display: flex;
    align-items: center;
  }
  
  .search-input input {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    outline: none;
    width: 200px;
    margin-right: 1rem;
    transition: border-color 0.3s;
  }
  
  .search-input input::placeholder {
    color: #999;
  }
  
  .activity-search-button {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.9rem;
    transition: background-color 0.3s;
  }
  
  .activity-search-button:hover {
    background-color: #0056b3;
  }
  