/* Offers.css */

.offers-tab {
  padding: 1rem;
  background-color: #f1f1f1;
  border-radius: 10px;
  position: relative;
}

.offers-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.no-offers-message {
  margin-top: 1rem;
  margin-left: 1rem;
  color: #666;
  font-size: 16px;
}

.offer-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.offer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offer-title {
  font-weight: bold;
  margin: 0;
  color: #333;
}

.offer-price {
  margin: 0;
  color: #666;
}

.offer-agent {
  margin: 0;
  color: #888;
}

.offer-actions {
  display: flex;
  gap: 0.5rem;
}

.view-button,
.respond-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover,
.respond-button:hover {
  background-color: #0056b3;
}

.offers-tab-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.offers-tab-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
