/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
    margin: 0;
    padding: 0;
    width: 100%; /* Ensures that the body takes up the full viewport width */
    height: 100%; /* Ensures that the body takes up the full viewport height */
    overflow-x: hidden; /* Prevents horizontal scroll */
  }

  .container {
    padding: 0;
    margin: 0 auto; /* Centers the content without adding horizontal padding */
  }

  body {
    font-family: 'Helvetica Neue', sans-serif;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;  /* Adjust this value based on your layout needs */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
