/* Main Container */
.public-listing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.pfl-content {
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
  padding: .5rem 4rem;
  width: 90%;
}

/* Property Header */
.pfl-property-header {
  margin-bottom: 2rem;
}

.pfl-property-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.pfl-property-location {
  display: block;
  font-size: 1.25rem;
  color: #4b5563;
  font-weight: 400;
  margin-top: 1rem;
}

/* Content Grid */
.pfl-content-grid {
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  gap: 2rem;
}

/* Main Content */
.pfl-main-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Property Details */
.pfl-property-details {
  background: white;
  border-radius: 12px;
  padding: 1.5rem 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.pfl-property-details:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pfl-details-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.5rem;
  align-items: start;
}

.pfl-detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.pfl-detail-label {
  font-size: 0.85rem;
  color: #6b7280;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.pfl-detail-value {
  font-size: 1.1rem;
  color: #111827;
  font-weight: 600;
}

/* Gallery Section */
.pfl-gallery-section {
  position: relative;
}

.pfl-gallery-container {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-bottom: 2rem;
}

.pfl-gallery-container:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pfl-main-image {
  width: 100%;
  height: 450px;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.pfl-main-image.loading {
  opacity: 0.7;
}

/* Image Count */
.pfl-image-count {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  backdrop-filter: blur(4px);
}

/* Price Overlay */
.pfl-price-overlay {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  backdrop-filter: blur(4px);
}

.pfl-price-amount {
  font-size: 1.25rem;
  font-weight: 700;
}

/* Gallery Controls */
.pfl-gallery-controls {
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  display: flex;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
}

.pfl-gallery-button {
  background: rgba(0, 0, 0, 0.85);
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: white;
  transition: all 0.2s ease;
}

.pfl-gallery-button:hover {
  background: rgba(0, 0, 0, 0.95);
  transform: scale(1.05);
}

.pfl-gallery-button:active {
  transform: scale(0.95);
}

/* Contact Form */
.pfl-contact-form {
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  align-self: start;
  min-width: 320px;
  transition: box-shadow 0.3s ease;
}

.pfl-contact-form:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pfl-form-container {
  padding: 1.75rem;
}

.pfl-form-container h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 0.75rem;
}

.pfl-form-container p {
  color: #4b5563;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.pfl-inquiry-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.pfl-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pfl-form-group label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #374151;
}

.pfl-form-group input,
.pfl-form-group select {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #1a1a1a;
  background-color: white;
}

.pfl-form-group input:hover,
.pfl-form-group select:hover {
  border-color: #d1d5db;
}

.pfl-form-group input:focus,
.pfl-form-group select:focus {
  border-color: #2563eb;
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.pfl-form-group input::placeholder {
  color: #9ca3af;
}

.pfl-request-button {
  background: #2563eb;
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.pfl-request-button:hover {
  background: #1d4ed8;
  transform: translateY(-1px);
}

.pfl-request-button:active {
  transform: translateY(0);
}

/* Loading State */
.pfl-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  gap: 1rem;
}

.pfl-loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2563eb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.pfl-loading-container p {
  color: #4b5563;
  font-size: 1.1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error Message */
.pfl-error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.pfl-error-message {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.pfl-error-message h2 {
  color: #dc2626;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.pfl-error-message p {
  color: #4b5563;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .pfl-content {
    padding: 1rem 3rem;
  }

  .pfl-details-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .pfl-content {
    padding: 1rem 2rem;
  }

  .pfl-content-grid {
    grid-template-columns: 1fr;
  }

  .pfl-contact-form {
    min-width: unset;
  }

  .pfl-main-image {
    height: 400px;
  }

  .pfl-property-details {
    padding: 1.25rem;
  }
}

@media (max-width: 768px) {
  .pfl-content {
    padding: 1rem;
  }

  .pfl-property-title {
    font-size: 2rem;
  }

  .pfl-details-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .pfl-main-image {
    height: 300px;
  }

  .pfl-price-overlay {
    padding: 0.75rem;
  }

  .pfl-price-amount {
    font-size: 1.25rem;
  }

  .pfl-form-container {
    padding: 1.25rem;
  }
}

@media (max-width: 480px) {
  .pfl-details-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .pfl-property-title {
    font-size: 1.75rem;
  }

  .pfl-property-location {
    font-size: 1.1rem;
  }
}