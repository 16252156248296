/* EditEmailModal.css */

.email-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-modal-content {
  background: white;
  border-radius: 8px;
  width: 350px; /* Reduced width */
  max-width: 90%;
  padding: 2rem; /* Adjusted padding for height */
  padding-top: 2.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden; /* Disable scrolling within the modal */
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.email-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.email-modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  padding-bottom: 2rem;
}

.edit-email-close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  cursor: pointer;
  color: #000000;
  transition: all 0.3s ease;
}

.edit-email-close-button:hover {
  color: #ffffff;
  background-color: #b12525;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.edit-email-close-button:before {
  content: '×';
  font-weight: bold;
  display: inline-block;
  line-height: 1;
  margin-bottom: 2px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.email-modal-body .email-form-group {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-modal-body label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.email-modal-body .email-form-control {
  width: 80%; /* Adjusted width for better alignment */
  padding: 0.75rem;
  border: 1px solid #868686;
  border-radius: 4px;
}

.email-modal-footer {
  display: flex;
  justify-content: center;
}

.email-save-button {
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold; /* Bold text for the button */
}

.email-save-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
  text-align: center;
}
