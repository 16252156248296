/* AgentInfo.css */

.agent-info-section {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  padding-top: 2rem;
}

.agent-info-content {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.agent-avatar {
  color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.agent-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.agent-details {
  flex: 1;
  min-width: 200px;
}

.brokerage-details {
  flex: 1;
  min-width: 200px;
}

.status-details {
  flex: 1;
  min-width: 200px;
}

.agent-details h3,
.brokerage-details .brokerage-name {
  margin: 0 0 0.65rem 0;
  font-size: 1.2rem;
  color: #2b2b2b;
}

.contact-info-value,
.address-value,
.license-value,
.status-value,
.timestamp-value {
  font-size: 0.95rem;
  color: #2b2b2b;
  margin: 0.5rem 0;
}

.license-label,
.status-label,
.timestamp-label {
  font-size: 0.95rem;
  color: #6c757d;
  margin: 0.5rem 0 0.25rem 0;
}

.info-block {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.status-box {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  border-radius: 12px;
  font-size: 0.85rem;
  text-align: center;
  display: inline-block;
}

.status-submitted {
  background-color: #e7e7e7;
  color: #333;
}

.status-under-review {
  background-color: #6f42c1;
  color: #fff;
}

.status-countered {
  background-color: #e78f37;
  color: #ffffff;
}

.status-accepted {
  background-color: #28a745;
  color: #fff;
}

.status-rejected {
  background-color: #dc3545;
  color: #fff;
}
