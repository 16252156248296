/* OfferToolBar.css */

.offer-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
  }
  
  .toolbar-left,
  .toolbar-right {
    display: flex;
    align-items: center;
  }
  
  .add-offer-button {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #898888;
    border-radius: 10px;
    padding: 10px 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    margin-right: 1rem;
  }
  
  .download-button {
    background-color: #ffffff;
    color: #262626;
    border: 1px solid #898888;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    margin-right: 0.8rem;
  }
  
  .add-offer-button:hover {
    transform: scale(1.05);
    background-color: #0056b3;
  }
  
  .download-button:hover {
    transform: scale(1.05);
    background-color: #ececec;
  }
  
  .pagination-button:disabled {
    background-color: #b0c4de;
    cursor: not-allowed;
  }
  
  .page-info {
    font-weight: 600;
    color: #333;
  }
  