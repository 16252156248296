/* OfferCard.css */

.offer-card-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Center elements horizontally */
  margin-bottom: 1rem;
}

.offer-avatar {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 1.25rem;
}

.offer-avatar-img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
}

.offer-agent-info {
  display: flex;
  flex-direction: column;
}

.offer-agent-name {
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.2rem;
  font-size: 1rem;
}

.offer-agent-email,
.offer-agent-phone {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  color: #666666;
}

.offer-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1.5rem;
  transition: box-shadow 0.3s;
}

.offer-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.offer-card-body {
  margin-bottom: 1rem;
}

.offer-price {
  text-align: center;
}

.offer-price h3 {
  padding-bottom: 0.1rem;
  font-size: 1.5rem;
  color: #333333;
}

.offer-actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.view-offer-button,
.respond-offer-button {
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.3s;
  font-weight: 500;
  margin-right: 0.25rem;
}

.respond-offer-button {
  background-color: #ffffff;
  color: #262626;
  border: 1px solid #898888;
}

.view-offer-button {
  background-color: #007bff;
}

.respond-offer-button:hover {
  background-color: #ececec;
}

.view-offer-button:hover {
  background-color: #0056b3;
}

.offer-details {
  margin-top: 1.5rem;
}

.offer-details p {
  margin: 0.7rem 0;
  font-size: 0.925rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  width: 100%;
  height: 0.5px;
  background-color: #e0e0e0;
  margin: 1rem auto;
}

.offer-card-footer {
  margin-top: 1rem;
}

.offer-card-footer p {
  margin: 1rem 0;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
}

.team-notes {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  width: 100%;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.875rem;
  outline: none;
}

/* Status Styles */
.status-box {
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  border-radius: 12px;
  font-size: 0.85rem;
  display: inline-block;
}

.status-submitted {
  background-color: #e7e7e7;
  color: #333;
}

.status-under-review {
  background-color: #6f42c1;
  color: #ffffff;
}

.status-countered {
  background-color: #e78f37;
  color: #ffffff;
}

.status-accepted {
  background-color: #28a745;
  color: #ffffff;
}

.status-rejected {
  background-color: #dc3545;
  color: #ffffff;
}
