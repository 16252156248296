/* DocuSignLoginModal.css */

.docusign-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .docusign-modal-content {
    background: white;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;
    padding: 2rem;
    padding-top: 2.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .docusign-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .docusign-modal-header h2 {
    margin: 0;
    font-size: 1.25rem;
    padding-bottom: 2rem;
  }
  
  .docusign-close-button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: #ffffff;
    border: 2px solid #d5d5d5;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    color: #000000;
    transition: all 0.3s ease;
  }
  
  .docusign-close-button:hover {
    color: #ffffff;
    background-color: #b12525;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .docusign-close-button:before {
    content: '×';
    font-weight: bold;
    display: inline-block;
    line-height: 1;
    margin-bottom: 2px;
  }
  
  .docusign-modal-body {
    padding-bottom: 2rem;
    text-align: center;
  }
  
  .docusign-modal-body p {
    font-size: 1rem;
    color: #666;
  }
  
  .docusign-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
  .docusign-login-button,
  .docusign-cancel-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .docusign-login-button:hover {
    background-color: #0056b3;
  }
  
  .docusign-cancel-button {
    background-color: #dc3545;
  }
  
  .docusign-cancel-button:hover {
    background-color: #c82333;
  }
  