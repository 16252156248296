/* Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 10;
}

.header-logo {
    height: 55px;
    cursor: pointer;
    transition: transform 0.3s;
    padding-right: 1.5rem;
    padding-left: 1rem;
    transition: transform 0.3s;
}

.header-logo:hover {
  transform: scale(1.05);
}

.header-group {
    display: flex;
    align-items: center;
}

.header-navigation,
.header-divider {
    display: flex;
}

.header-link {
    margin: 0 1rem;
    text-decoration: none;
    color: #444;
    font-weight: 600;
    font-size: 1.05rem;
    transition: color 0.3s ease;
}

.header-link:hover,
.header-link:focus {
    color: rgb(27, 92, 243);
}

.header-divider {
    height: 30px;
    border-left: 2px solid #8a8a8a;
    margin: 0 2.2rem;
}

.header-actions {
    display: flex;
    align-items: center;
}

.header-signup-button {
    padding: 0.6rem 1.2rem;
    margin-left: 20px;
    background-color: rgb(27, 92, 243);
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
    outline: none;
}

.header-signup-button:hover {
    background-color: rgb(0, 83, 172);
}

.header-logout-button {
    padding: 0.6rem 1.2rem;
    margin-left: 20px;
    background-color: rgb(27, 92, 243);
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
    outline: none;
    font-size: 1rem;
}

.header-logout-button:hover {
    background-color: rgb(0, 83, 172);
}

/* Responsive design adjustments for small screens */
@media (max-width: 768px) {
    .header {
        padding: 0.75rem 1.5rem;
        flex-wrap: wrap; /* Ensures content wraps and doesn't overflow */
    }

    .header-logo {
        height: 50px;
        flex-basis: 100%; /* Makes the logo take full width */
        order: 1; /* Reorders the logo to the top */
        text-align: center; /* Centers the logo */
    }

    .header-group {
        width: 100%; /* Ensures group takes full width */
        justify-content: space-between; /* Spreads items to full width */
        order: 3; /* Places navigation at the bottom */
    }

    .header-navigation,
    .header-divider {
        display: none; /* Still hide navigation and divider */
    }

    .header-actions {
        order: 2; /* Places actions below logo */
        justify-content: center; /* Centers actions */
        width: 100%; /* Full width to contain buttons */
    }

    .header-signup-button {
        padding: 0.5rem 1rem;
    }
}
